export const CACHE_TIMEOUT = 3600 // One hour
export const LOCAL_CACHE_TIMEOUT_MS = CACHE_TIMEOUT * 1000
export const MAXIMUM_VIEW_SIZE = 24
export const PRODUCT_CARD_BANNER_WIDGET_TYPE = 'ProductCardBannerWidget'

// A-B flags
export const SHOW_LEGAL_INFO_LISTER_SEARCH_PAGES = 'show-legal-info-on-lister-search-pages'
export const SHOW_PRODUCT_CARD_AVAILABILITY_STATUS = 'show-product-card-availability-status-2'
export const SHOW_STICKY_TOP_FILTERS = 'show-sticky-top-filters'
export const SHOW_TOP_SUBCATEGORIES = 'show-top-subcategories'
export const SHOW_BV_RATING_ON_LISTER_CARDS = 'show-bv-rating-on-lister-cards'
export const SHOW_NEW_AVAILABILITY_FILTER_LABELS = 'show-new-availability-filter-labels'
export const SHOW_ALTERNATIVES_ADD_TO_BASKET = 'show-alternatives-add-to-basket'
export const SEARCH_FE_TOGGLES_TO_DATA_LAYER = 'search-fe-toggles-to-data-layer'
export const SHOW_PAGINATION_SOFT_CONTENT_OVERVIEW = 'show-pagination-soft-content-overview'

export const PAGE = 'page'
export const OVERVIEW_KEYS = [
  'overview.no-results',
  'overview.popular-search',
  'overview.load-more',
  'overview.sort',
  'overview.search-placeholder',
  'overview.filter-type'
]

export const PAGE_TYPES_KEYS_AND_ROUTES = [
  'blog',
  'product-advice',
  'diy-tip',
  'commercial-page',
  'campaign-page',
  'project-page',
  'maintenance-advice',
  'step-by-step',
  'brand-page',
  'loyalty-page',
  'm2m-landing',
  'service-page',
  'do-it-for-me',
  'i-frame-page',
  'collection-page',
  'splash-screen',
  'press-release'
]
export const BLOG_KEY = 'blog'
export const SBS_KEY = 'step-by-step'

export const COST_KEY = `${SBS_KEY}.cost`
export const DIFFICULTY_KEY = `${SBS_KEY}.difficulty`
export const TIME_TO_READ_KEY = `${BLOG_KEY}.time-to-read`
export const READ_THIS_KEY = 'suggested-articles.card.read'

export const COST_MAPPER = {
  cheap: 1,
  average: 2,
  expensive: 3
}

export const DIFFICULTY_MAPPER = {
  easy: 1,
  medium: 2,
  hard: 3
}

export const OVERVIEW_VIEW_SIZE = 12
export const OVERVIEW_FILTER_TYPE = 'pageType'
export const OVERVIEW_FILTER_TAG_TYPE = 'tags'
export const OVERVIEW_FILTER_VALID_TYPE = 'valid'
export const OVERVIEW_GRID_DATA = {
  mobileColumns: 1,
  mobileRowsPerLoad: 4,
  desktopColumns: 4,
  visibleRows: 3,
  desktopRowsPerLoad: 3
}
